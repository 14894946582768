import React from 'react';
//@ts-ignore
import * as styles from './Button.module.css';

interface Props {
    type: 'button' | 'submit';
    visualStyle: 'outlineDark' | 'outlineLight';
    onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): any;
}

const Button: React.FC<Props> = ({ onClick, visualStyle, type, children }) => {
    const buttonStyle = styles[visualStyle];

    return (
        <button type={type} className={buttonStyle} onClick={onClick}>
            {children}
        </button>
    );
};

export default Button;
