import React, { useContext } from 'react';
import { RichTextEl } from '../Global/RichText';
import {
    navIconCard,
    navRichTextCard,
    navContentCard,
    navStandardLink,
    NavGroup,
} from './index';
import { BuildLinkHref } from '../Global/BuildLinkHref';
import { getIcon, getMediaData, getMultiChoice } from '../Global/DataUtils';
import {
    CTAConfiguration,
    KontentIcon,
    KontentStringValue,
} from '../../../types/KontentProps';
//@ts-ignore
import * as styles from './navigation.module.css';
import { AuthorStyles } from './index';
import { PageMetadataContext } from '../../templates/PageMetaDataContext';
import { defaultDomainCountryCodes } from '../../global/countries';

const getCta = (data: CTAConfiguration) => {
    return {
        label: data.cta_configuration__cta_label?.value,
        location: BuildLinkHref(
            data.cta_configuration__cta_location,
            data.cta_configuration__cta_location_anchor_point
        ),
        target:
            getMultiChoice(data.cta_configuration__cta_window_action) ===
            'same_window'
                ? '_self'
                : '_new',
    };
};

export const renderNavIconCard = (
    link: navIconCard,
    authorStyles: AuthorStyles,
    panelName: KontentStringValue
) => {
    const cta = getCta(link.elements as CTAConfiguration);
    const iconData = getIcon(link.elements.icon as KontentIcon);
    const pageMetadata = useContext(PageMetadataContext);
    const preferredLanguage = pageMetadata.preferredLanguage || 'default';
    const linkHref =
        defaultDomainCountryCodes.indexOf(preferredLanguage.toLowerCase()) >
            -1 || cta.location.split('/')[1] == preferredLanguage.toLowerCase()
            ? cta.location
            : `/${preferredLanguage.toLowerCase()}${cta.location}`;
    return (
        <div className={styles.iconCard}>
            <a
                id={`navigation__${panelName?.value?.replace(
                    / /g,
                    '-'
                )}__${cta?.label?.replace(/ /g, '-')}`}
                href={linkHref}
                target={cta.target}
                style={{ color: authorStyles.accentColor }}
            >
                <picture>
                    <img
                        src={iconData.url}
                        alt={iconData.description || iconData.name}
                    />
                </picture>
                {cta.label}
            </a>
        </div>
    );
};

export const renderRichTextCard = (
    card: navRichTextCard,
    panelName: KontentStringValue | undefined
) => {
    return (
        <div className={styles.richTextCard}>
            <RichTextEl
                richText={card.elements?.content}
                giveID={true}
                panelName={panelName}
            />
        </div>
    );
};

export const renderContentCard = (
    card: navContentCard,
    authorStyles: AuthorStyles,
    panelName?: KontentStringValue
) => {
    const { description, headline } = card.elements;
    const cta = getCta(card.elements);
    const image = getMediaData(card.elements.image);
    const pageMetadata = useContext(PageMetadataContext);
    const preferredLanguage = pageMetadata.preferredLanguage || 'default';
    const linkHref =
        defaultDomainCountryCodes.indexOf(preferredLanguage.toLowerCase()) >
            -1 ||
        cta.location.includes('https' || 'http') ||
        cta.location.split('/')[1] == preferredLanguage.toLowerCase()
            ? cta.location
            : `/${preferredLanguage.toLowerCase()}${cta.location}`;
    return (
        <>
            <div className={styles.contentCard}>
                <a
                    id={`navigation__${panelName?.value?.replace(
                        / /g,
                        '-'
                    )}__${headline?.value?.replace(/ /g, '-')}`}
                    href={linkHref}
                    target={cta.target}
                    className={styles.contentCardLink}
                >
                    {image?.url && (
                        <img src={image.url} alt={image?.description} />
                    )}
                    <div
                        className={styles.headline}
                        style={{ color: authorStyles.accentColor }}
                    >
                        {headline.value}
                    </div>
                    <div className={styles.description}>
                        <RichTextEl richText={description} />
                    </div>
                    <div className={styles.cta}>{cta.label}</div>
                </a>
            </div>
        </>
    );
};

export const renderStandardLink = (
    link: navStandardLink,
    panelName: KontentStringValue
) => {
    const cta = getCta(link.elements as CTAConfiguration);
    const pageMetadata = useContext(PageMetadataContext);
    const preferredLanguage = pageMetadata.preferredLanguage || 'default';
    const linkHref =
        defaultDomainCountryCodes.indexOf(preferredLanguage.toLowerCase()) >
            -1 ||
        cta.location.includes('https' || 'http') ||
        cta.location.split('/')[1] == preferredLanguage.toLowerCase()
            ? cta.location
            : `/${preferredLanguage.toLowerCase()}${cta.location}`;
    return (
        <div className={styles.standardLink}>
            <a
                id={
                    panelName?.value
                        ? `navigation__${panelName.value}__${cta.label}`?.replace(
                              / /g,
                              '-'
                          )
                        : undefined
                }
                href={linkHref}
                target={cta.target}
            >
                {cta.label}
            </a>
        </div>
    );
};
