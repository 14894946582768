import React from 'react';
import SvgArrowLgLeft from './SvgArrowLgLeft';
import SvgArrowLgRight from './SvgArrowLgRight';

//@ts-ignore
import * as styles from './arrow-lg-button.module.css';

interface Props {
    className?: string;
    strokeColor: string;
    direction: 'right' | 'left';
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ArrowLgButton: React.FC<Props> = ({
    className,
    strokeColor,
    direction,
    handleClick,
}) => {
    return (
        <button
            className={`${className} ${styles.button}`}
            onClick={handleClick}
        >
            {direction === 'right' && (
                <SvgArrowLgRight strokeColor={strokeColor} />
            )}

            {direction === 'left' && (
                <SvgArrowLgLeft strokeColor={strokeColor} />
            )}
        </button>
    );
};

export default ArrowLgButton;
