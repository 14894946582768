import * as React from 'react';

interface Props {
    strokeColor: string;
}

const SvgArrowLgRight: React.FC<Props> = ({ strokeColor }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.36 34.2">
            <g data-name="Layer 2">
                <path
                    data-name="Path 509"
                    d="M1 33.2l16.36-16.1L1 1"
                    fill="none"
                    stroke={strokeColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                />
            </g>
        </svg>
    );
};

export default SvgArrowLgRight;
