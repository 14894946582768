import React, { useContext } from 'react';
import Navigation from './Navigation';
import { graphql } from 'gatsby';
import {
    KontentRichText,
    BrandColor,
    KontentStringValue,
    KontentMedia,
    KontentIcon,
    CTAConfiguration,
    KontentNumberValue,
    CTALocation,
    KontentRadioMultiChoice,
} from '../../../types/KontentProps';
import { CountrySelectProps } from '../CountrySelectOverlay';
import { PageMetadataContext } from '../../templates/PageMetaDataContext';
import { defaultDomainCountryCodes } from '../../global/countries';

export type navColorConfig = {
    value: {
        elements: {
            background_color: BrandColor;
            background_opacity: KontentNumberValue;
            font_color: BrandColor;
        };
    }[];
};

type EnableChat = 'yes' | 'no';

type contactUsConfig = {
    value: {
        id?: string;
        elements: {
            call_us_label: KontentStringValue;
            call_us_phone_number: KontentStringValue;
            copy: KontentRichText;
            cta_configuration__cta_label: KontentStringValue;
            cta_configuration__cta_location: CTALocation;
            cta_configuration__cta_location_anchor_point: KontentStringValue;
            cta_configuration__cta_window_action: any;
            email_address?: KontentStringValue;
            email_label: KontentStringValue;
            enable_chat: KontentRadioMultiChoice<EnableChat>;
            engagement_headline: KontentStringValue;
        };
    }[];
};

type navElementSystemType = {
    type:
        | 'nav___icon_card'
        | 'nav___content_card_with_image'
        | 'nav___rich_text_card'
        | 'nav___standard_link';
};

export type navIconCard = {
    system: navElementSystemType;
    elements: CTAConfiguration & { icon: KontentIcon };
};

export type navStandardLink = {
    system: navElementSystemType;
    elements: CTAConfiguration;
};

export type navRichTextCard = {
    system: navElementSystemType;
    elements: {
        content: KontentRichText;
    };
};
export type navContentCard = {
    system: navElementSystemType;
    elements: CTAConfiguration & {
        description: KontentRichText;
        headline: KontentStringValue;
        image: KontentMedia;
    };
};

export type DesktopNavElements = navIconCard | navRichTextCard | navContentCard;
export type MobileNavElements = navIconCard | navStandardLink;
export interface NavGroup {
    elements: {
        category_name: KontentStringValue;
        mobile_elements: { value: MobileNavElements[] };
        desktop_elements: { value: DesktopNavElements[] };
        iscta?: { value: { name: string }[] };
        display_name?: KontentStringValue;
        element?: {
            value: {
                id: string;
                elements: {
                    slug: {
                        url: string;
                    };
                };
            }[];
        };
        cta_window_action?: { value: { codename: string }[] };
        anchor?: KontentStringValue;
        cta_location?: {
            value: {
                id: string;
                elements: {
                    slug: {
                        url: string;
                    };
                };
            }[];
        };
    };
}

type Navigation = {
    elements: {
        account_cta_icon: KontentIcon;
        account_cta_location: KontentStringValue;
        account_mobile_label: KontentStringValue;
        color_configuration: navColorConfig;
        contact_us_icon: KontentIcon;
        contact_us_overlay_configuration: contactUsConfig;
        groups: { value: NavGroup[] };
        long_logo: KontentMedia;
        short_logo: KontentMedia;
        main_nav_icon: KontentIcon;
        mobile_copyright_copy: KontentStringValue;
        search_icon: KontentIcon;
        shop_cta_icon: KontentIcon;
        shop_cta_location: KontentStringValue;
        shop_mobile_label: KontentStringValue;
        country_select_overlay_configuration: { value: CountrySelectProps[] };
        aria_label?: KontentStringValue;
        keyword_hint_text?: KontentStringValue;
        search_panel_title?: KontentStringValue;
        desktop_search_cta_label?: KontentStringValue;
    };
};

export type LongLogo = KontentMedia;
export type ShortLogo = KontentMedia;

export interface AuthorStyles {
    backgroundColor?: string;
    color?: string;
    accentColor?: string;
    inline?: {
        background?: string;
        color: string;
        backgroundImage?: string;
    };
}

export interface NavigationProps {
    account_cta_icon: KontentIcon;
    account_cta_location: KontentStringValue;
    account_mobile_label: KontentStringValue;
    color_configuration: navColorConfig;
    contact_us_icon: KontentIcon;
    contact_us_overlay_configuration: contactUsConfig;
    groups: { value: NavGroup[] };
    long_logo: KontentMedia;
    short_logo: KontentMedia;
    main_nav_icon: KontentIcon;
    mobile_copyright_copy: KontentStringValue;
    search_icon: KontentIcon;
    shop_cta_icon: KontentIcon;
    shop_cta_location: KontentStringValue;
    shop_mobile_label: KontentStringValue;
    country_select_overlay_configuration: {
        value: { elements: CountrySelectProps }[];
    };
    aria_label: KontentStringValue;
    keyword_hint_text: KontentStringValue;
    search_panel_title: KontentStringValue;
    desktop_search_cta_label: KontentStringValue;
}

const NavigationContainer: React.FC<NavigationProps> = (props) => {
    const pageMetadata = useContext(PageMetadataContext);
    const langPrefix =
        pageMetadata?.preferredLanguage == undefined ||
        defaultDomainCountryCodes.indexOf(
            pageMetadata.preferredLanguage.toLowerCase()
        ) > -1
            ? 'default'
            : pageMetadata.preferredLanguage;
    return <Navigation {...props} preferredLanguage={langPrefix} />;
};

export default NavigationContainer;

export const fragmentNavigation = graphql`
    fragment Navigation on kontent_item_component___navigation {
        elements {
            account_cta_icon {
                value {
                    ... on kontent_item_media___icon {
                        id
                        elements {
                            ...kontentIcon
                        }
                    }
                }
            }
            account_cta_location {
                value
            }
            account_mobile_label {
                value
            }
            color_configuration {
                value {
                    ... on kontent_item_nav___color_configuration {
                        id
                        elements {
                            background_color {
                                ...kontentColor
                            }
                            background_opacity {
                                value
                            }
                            font_color {
                                ...kontentColor
                            }
                        }
                    }
                }
            }
            contact_us_icon {
                value {
                    ... on kontent_item_media___icon {
                        id
                        elements {
                            ...kontentIcon
                        }
                    }
                }
            }
            contact_us_overlay_configuration {
                ...contactOverlay
            }
            groups {
                ...navGroups
            }
            long_logo {
                ...kontentImage
            }
            short_logo {
                ...kontentImage
            }
            main_nav_icon {
                value {
                    ... on kontent_item_media___icon {
                        id
                        elements {
                            ...kontentIcon
                        }
                    }
                }
            }
            mobile_copyright_copy {
                value
            }
            search_icon {
                value {
                    ... on kontent_item_media___icon {
                        id
                        elements {
                            ...kontentIcon
                        }
                    }
                }
            }
            shop_cta_icon {
                value {
                    ... on kontent_item_media___icon {
                        id
                        elements {
                            ...kontentIcon
                        }
                    }
                }
            }
            shop_cta_location {
                value
            }
            shop_mobile_label {
                value
            }
            country_select_overlay_configuration {
                ...countrySelectOverlay
            }
            aria_label {
                value
            }
            keyword_hint_text {
                value
            }
            search_panel_title {
                value
            }
            desktop_search_cta_label {
                value
            }
        }
    }

    fragment navGroups on kontent_item_modular_content_element_value {
        value {
            ... on kontent_item_nav___category {
                id
                elements {
                    category_name {
                        value
                    }
                    mobile_elements {
                        ...navMobileStandardLink
                        ...navIconCard
                    }
                    desktop_elements {
                        ...navIconCard
                        ...navRichTextCard
                        ...navContentCardWithImage
                    }
                }
            }
            ... on kontent_item_nav___cta_button {
                id
                elements {
                    iscta {
                        value {
                            name
                        }
                    }
                    display_name {
                        value
                    }
                    element {
                        value {
                            id
                            ... on kontent_item_layout___master_page {
                                id
                                elements {
                                    slug {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ... on kontent_item_nav___direct_link {
                id
                elements {
                    display_name {
                        value
                    }
                    cta_window_action {
                        value {
                            codename
                        }
                    }
                    anchor {
                        value
                    }
                    cta_location {
                        value {
                            id
                            ... on kontent_item_layout___master_page {
                                id
                                elements {
                                    slug {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    fragment navMobileStandardLink on kontent_item_modular_content_element_value {
        value {
            ... on kontent_item_nav___standard_link {
                id
                system {
                    type
                }
                elements {
                    cta_configuration__cta_label {
                        value
                    }
                    cta_configuration__cta_location {
                        ...kontentCtaLocation
                    }
                    cta_configuration__cta_location_anchor_point {
                        value
                    }
                    cta_configuration__cta_window_action {
                        value {
                            codename
                        }
                    }
                }
            }
        }
    }

    fragment navIconCard on kontent_item_modular_content_element_value {
        value {
            ... on kontent_item_nav___icon_card {
                id
                system {
                    type
                }
                elements {
                    cta_configuration__cta_label {
                        value
                    }
                    cta_configuration__cta_location {
                        ...kontentCtaLocation
                    }
                    cta_configuration__cta_location_anchor_point {
                        value
                    }
                    cta_configuration__cta_window_action {
                        value {
                            codename
                        }
                    }
                    icon {
                        value {
                            ... on kontent_item_media___icon {
                                id
                                elements {
                                    ...kontentIcon
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    fragment navRichTextCard on kontent_item_modular_content_element_value {
        value {
            ... on kontent_item_nav___rich_text_card {
                id
                system {
                    type
                }
                elements {
                    content {
                        ...kontentRichText
                    }
                }
            }
        }
    }

    fragment navContentCardWithImage on kontent_item_modular_content_element_value {
        value {
            ... on kontent_item_nav___content_card_with_image {
                id
                system {
                    type
                }
                elements {
                    cta_configuration__cta_label {
                        value
                    }
                    cta_configuration__cta_location {
                        ...kontentCtaLocation
                    }
                    cta_configuration__cta_location_anchor_point {
                        value
                    }
                    cta_configuration__cta_window_action {
                        value {
                            codename
                        }
                    }
                    description {
                        ...kontentRichText
                    }
                    headline {
                        value
                    }
                    image {
                        ...kontentImage
                    }
                }
            }
        }
    }

    fragment contactOverlay on kontent_item_modular_content_element_value {
        value {
            ... on kontent_item_component___contact_overlay {
                id
                elements {
                    call_us_label {
                        value
                    }
                    call_us_phone_number {
                        value
                    }
                    copy {
                        value
                        images {
                            url
                        }
                        links {
                            url_slug
                            type
                            link_id
                            codename
                        }
                    }
                    cta_configuration__cta_label {
                        value
                    }
                    cta_configuration__cta_location {
                        ...kontentCtaLocation
                    }
                    cta_configuration__cta_location_anchor_point {
                        value
                    }
                    cta_configuration__cta_window_action {
                        value {
                            codename
                        }
                    }
                    email_address {
                        value
                    }
                    email_label {
                        value
                    }
                    enable_chat {
                        value {
                            codename
                            name
                        }
                    }
                    engagement_headline {
                        value
                    }
                }
            }
        }
    }

    fragment countrySelectOverlay on kontent_item_modular_content_element_value {
        value {
            ... on kontent_item_component___country_select_overlay {
                ...CountrySelectOverlay
            }
        }
    }
`;
