import React, { useState } from 'react';
import { graphql } from 'gatsby';
import {
    KontentStringValue,
    KontentRadioMultiChoice,
    KontentRichText,
} from '../../../types/KontentProps';
import RichText from '../Global/RichText';
import { useForm } from 'react-hook-form';
import Button from '../Global/Button';
import CloseButton from '../Global/CloseButton';
import Modal from 'react-modal';

//@ts-ignore
import * as styles from './contact-us-overlay.module.css';

type Topic = {
    name: string;
    email: string;
    topics: string;
};

type EnableChat = 'yes' | 'no';

interface Props {
    call_us_label: KontentStringValue;
    call_us_phone_number: KontentStringValue;
    copy: KontentRichText;
    cta_configuration__cta_label: KontentStringValue;
    cta_configuration__cta_location: any;
    cta_configuration__cta_location_anchor_point: KontentStringValue;
    cta_configuration__cta_window_action: any;
    email_address?: KontentStringValue;
    email_label: KontentStringValue;
    enable_chat: KontentRadioMultiChoice<EnableChat>;
    engagement_headline: KontentStringValue;
    isOpen?: boolean;
    toggle?: (status: boolean) => void;
}

const ContactUsOverlay: React.FC<Props> = ({ ...props }) => {
    const [visible, setVisible] = useState(true);
    const contactEmail = props.email_address?.value;
    const callUsPhoneNumber = props.call_us_phone_number.value;
    const domain =
        typeof window! == 'undefined' ? '' : window.location.hostname;
    const enableChat = props.enable_chat.value[0]?.codename;
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Topic>();
    const onSubmit = (data: Topic) => console.log(data);

    const handleCloseClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setVisible(false);
        props.toggle && props.toggle(false);
    };

    const renderContactPhoneAndEmail = () => (
        <div className={styles.contact}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <p className={styles.subheading}>
                        {props.email_label.value}
                    </p>
                    <p>
                        <a
                            href={`mailto:${contactEmail}?&subject=Inquiry from ${domain}&body=`}
                        >
                            {contactEmail}
                        </a>
                    </p>
                </div>
                <div>
                    <p className={styles.subheading}>
                        {props.call_us_label.value}
                    </p>
                    <p>
                        <a href={`tel:${callUsPhoneNumber}`}>
                            {callUsPhoneNumber}
                        </a>
                    </p>
                </div>
                {enableChat === 'yes' && (
                    <div>
                        <p className={styles.subheading}>Chat With Us</p>
                        <input
                            type="text"
                            placeholder="MY NAME IS"
                            {...register('name', { required: true })}
                        ></input>
                        <input
                            type="email"
                            placeholder="MY EMAIL ADDRESS IS"
                            {...register('email', { required: true })}
                        ></input>
                        {/* TODO: this might need to change when connected to Chat feature. */}
                        <select
                            id="topics"
                            {...register('topics', { required: true })}
                            defaultValue=""
                        >
                            <option disabled value="">
                                I Need support with
                            </option>
                            <option value="mpulse">mPulse</option>
                            <option value="signature">Signature</option>
                            <option value="solo">SOLO</option>
                            <option value="luminir">Luminir/Accessory</option>
                            <option value="unknown">Unknown</option>
                        </select>

                        <Button
                            type="submit"
                            visualStyle="outlineDark"
                            onClick={() => {
                                window.open('/open_chat', '_blank');
                            }}
                        >
                            Chat With Us
                        </Button>
                    </div>
                )}
            </form>
        </div>
    );

    return (
        <Modal
            isOpen={props.isOpen || visible}
            contentLabel="Contact Us Overlay"
            onRequestClose={handleCloseClick}
            style={{
                overlay: {
                    position: 'absolute',
                    zIndex: 20000,
                },
                content: {
                    position: 'absolute',
                    top: '1rem',
                    left: '1rem',
                    right: '1rem',
                    bottom: '1rem',
                },
            }}
        >
            <div className={styles.wrapper}>
                <div className={styles.innerContent}>
                    <div className={styles.contactUsPanel}>
                        <h2 className={styles.headline}>Contact Us</h2>

                        {renderContactPhoneAndEmail()}
                    </div>
                    <div className={styles.somethingElsePanel}>
                        <h2 className={styles.headline}>
                            {props.engagement_headline.value}
                        </h2>

                        <div>{RichText(props.copy)}</div>

                        <Button
                            type="button"
                            visualStyle="outlineDark"
                            onClick={() => {
                                window.open('/contact_us', '_blank');
                            }}
                        >
                            {props.cta_configuration__cta_label.value}
                        </Button>
                    </div>
                </div>
                <div className={styles.closeButton}>
                    <CloseButton
                        visualStyle="orange"
                        onClick={handleCloseClick}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ContactUsOverlay;

export const fragmentContactUsOverlay = graphql`
    fragment ContactUsOverlay on kontent_item_component___contact_overlay {
        elements {
            call_us_label {
                value
            }
            call_us_phone_number {
                value
            }
            copy {
                value
            }
            cta_configuration__cta_label {
                value
            }
            cta_configuration__cta_location {
                value {
                    ... on kontent_item_layout___master_page {
                        id
                        elements {
                            slug {
                                value
                            }
                        }
                    }
                    ... on kontent_item_content_item___third_party_link {
                        elements {
                            third_party_url {
                                value
                            }
                        }
                    }
                }
            }
            cta_configuration__cta_location_anchor_point {
                value
            }
            cta_configuration__cta_window_action {
                value {
                    codename
                }
            }
            email_address {
                value
            }
            email_label {
                value
            }
            enable_chat {
                value {
                    codename
                }
            }
            engagement_headline {
                value
            }
        }
    }
`;
