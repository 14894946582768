import React from 'react';
//@ts-ignore
import * as styles from './show-more-arrow.module.css';
import SvgDownArrowSm from './SvgDownArrowSm';

interface Props {
    btnText: string;
    strokeColor: string;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    transform: string;
}

const ShowMoreArrow: React.FC<Props> = ({
    btnText,
    strokeColor,
    handleClick,
    transform,
}) => {
    return (
        <button
            className={styles.showMore}
            onClick={handleClick}
            style={{ color: strokeColor }}
        >
            {btnText}
            <SvgDownArrowSm strokeColor={strokeColor} transform={transform} />
        </button>
    );
};

export default ShowMoreArrow;
